@import '../../scss/config';

:root {
    --sidebar-width: 300px;
    --header-height: 48px;
}
@media (min-width: $breakpointDesktop) {
    :root {
        --sidebar-width: 300px;
        --header-height: 60px;
    }
}

.Layout {
    &__header {
        height: var(--header-height);
        width: 100%;
        z-index: 200;
        position: fixed;
        left: 0;
        top: 0;
        background: $colorLight;
        box-shadow: $colorGreyLighter 0 1px 7px;
    }
    &__sidebar {
        width: var(--sidebar-width);
        height: 100%;
        position: fixed;
        top: var(--header-height);
        bottom: 0;
        z-index: 100;
        background: $colorLight;
        transition: all 200ms cubic-bezier(0.820, 0.085, 0.395, 0.895);;
        transform: translateX(-100%);
        &__inner {
            background: $colorLight;
            visibility: visible;
            overflow: auto;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            &::-webkit-scrollbar {
                width: 6px;
            }
            &::-webkit-scrollbar-thumb {
                background-color: #717171;
            }
            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
                background-color: #F5F5F5;
            }
        }
        &__content {
            visibility: visible;
            height: 100%;
            border-right: 1px solid $colorGreyLight;
            > .MuiList-padding {
                padding-top: 0;
            }
        }
    }
    &__content {
        width: 100%;
        min-height: 100%;
        padding-top: var(--header-height);
        &__inner {
            padding: 10px 15px;
            @media (min-width: $breakpointDesktop) {
                max-width: 1920px;
                margin: auto;
            }
        }
    }
    &--openSidebar {
        .Layout__sidebar {
            transform: translateX(0);
        }
        .Layout__content {
            @media (min-width: $breakpointDesktop) {
                padding-left: var(--sidebar-width);
            }
        }
    }

    &--mobileSearchBar {
        @media (max-width: ($breakpointMobileMax)) {
            .Header__search {
                display: flex;
                z-index: 1000;
                background: $colorLight;
                form {
                    flex-grow: 1;
                }
            }
            .Layout__burger {
                display: none;
            }
            input[type="text"] {
                padding-left: 40px!important;
            }
        }
    }
}
