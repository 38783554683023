@import '../../scss/config';

.MultipleValuesInput {
  &__selected {
    position: relative;
    min-width: 50%;
    border-radius: 4px;
    color: rgba(0, 0, 0, 0.87);
    cursor: text;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 20px 15px;
    &__noValue {
      display: flex;
      align-items: center;
      font-size: 0.9rem;
      color: rgba(0, 0, 0, 0.54);
      padding: 0 5px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      margin-bottom: 12px;
      margin-top: 4px;
    }
    &__value {
      margin-right: 6px;
      margin-bottom: 8px;
      display: inline-block;
    }
  }
  &__label {
    position: absolute;
    transform-origin: top left;
    top: 0;
    left: 0;
    transform: translate(14px, -6px) scale(0.75);
    z-index: 1;
    background: #fff;
    color: rgba(0, 0, 0, 0.54);
    padding: 0 5px;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1;
    letter-spacing: 0.00938em;
  }

  &__add {
    position: relative;
    top: -4px;
    border-radius: 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-top: none;
    background: #fafafa;
    padding: 10px;
    > span {
      cursor: pointer;
      margin-right: 6px;
      margin-bottom: 8px;
      display: inline-block;
      .MuiChip-icon {
        color: rgba(0, 0, 0, 0.26);
      }
      .MuiChip-root {
        cursor: pointer;
      }
      &:hover {
        .MuiChip-icon {
          color: rgba(0, 0, 0, 0.4);
        }
      }
    }
    &__label {
      font-size: 0.8rem;
      color: rgba(0, 0, 0, 0.54);
      padding: 0 5px;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      margin-bottom: 8px;
    }
  }
  .TextInputWithConfirmCancel {
    margin-top: 4px;
  }
}

@media (min-width: $breakpointTablet) {
  .MultipleValuesInput {
    &__select {
      display: flex;
      gap: 20px;
    }
    &__add {
      border-radius: 4px;
      border: 1px solid rgba(100, 100, 100, 0.1);
    }
  }
}
