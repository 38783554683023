@import '../../../scss/config';

.LinkStyleButton {
    font-family: $baseFont;
    text-decoration: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    background: transparent;
    color: $colorDark;
    display: inline;
    border: none;
    cursor: pointer;
    font-weight: normal;
    font-size: 16px;
}