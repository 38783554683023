@import '../../../scss/config';

.PlayStateIndicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: $colorLight;
  pointer-events: none;
  display: none;
  &__play, &__pause {
    opacity: 0;
    width: 80px;
    height: 80px;
    margin-left: -40px;
    margin-top: -40px;
    position: absolute;
    top: 50%;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 300ms ease-in-out;
  }
  &--playing {
    .PlayStateIndicator__play {
      opacity: 1;
    }
    .PlayStateIndicator__pause {
      opacity: 0;
    }
  }
  &--paused {
    .PlayStateIndicator__play {
      opacity: 0;
    }
    .PlayStateIndicator__pause {
      opacity: 1;
    }
  }
  svg {
    font-size: 80px;
  }
  @media screen and (min-width: $breakpointTablet) {
    display: block;
  }
}
