@import '../../../../scss/config';

.PlayButton, .PauseButton {
  @media screen and (max-width: $breakpointMobileMax) {
    margin-right: 10px;
    svg {
      font-size: 35px;
    }
  }
}
