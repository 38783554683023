.Uploads {
  &__videos {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  &__divider {
    min-width: 100%;
    padding: 40px 0;
  }
}
