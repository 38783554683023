@import '../../../../scss/config';

.Info {
  display: inline-flex;
  align-items: center;
  color: $colorGreyDark;
  padding: 5px;
  @media (min-width: $breakpointTablet) {
    align-items: flex-start;
  }
  &--highlight {
    background: #eaf8fd;
  }
  &__message {
    padding-top: 3px;
    padding-left: 10px;
  }
  &__spacer {
    display: block;
    height: 0;
    line-height: 0;
    width: 100%;
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
