.FilterItem {
    .LinkStyleButton {
        text-align: left;
        word-break: break-word;
        margin-bottom: 5px;
    }
    &--termsWithOr, &--termsWithAnd {
        ul {
            max-height: 200px;
            overflow-x: visible;
            overflow-y: scroll;
            list-style-type: none;
            margin: 0;
            padding: 0;
        }
        .FilterItem__activeValues {
            margin-bottom: 10px;
            span {
                display: inline-block;
                margin-right: 3px;
                margin-bottom: 5px;
                .removeValue {
                    background: #e2e2e2;
                    border-radius: 11px;
                    text-decoration: none;
                    color: #333;
                    padding: 2px 7px;
                    font-size: 15px;
                }
                &:after {
                    content: ' und ';
                    font-size: 11px;
                }
                &:last-child:after {
                    display: none!important;
                }
            }
        }
    }
    &--termsWithOr {
        .FilterItem__activeValues {
            span {
                &:after{
                    content: ' oder ';
                }
            }
        }
    }
}