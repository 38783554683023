.ProgressBarThumbnail {
  position: absolute;
  bottom: 30px;
  &__time {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    span {
      background: #333;
      padding: 2px 10px;
      margin: auto auto 5px;
    }
  }
}
