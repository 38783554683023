@import '../../../../scss/config';

.Volume {
  color: $colorLight;
  display: none;
  @media screen and (min-width: $breakpointTablet) {
    display: flex;
    width: 120px;
    margin-right: 30px;
    padding-top: 2px;
  }
}
