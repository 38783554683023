@import './config';
@import './breakpoints';

html, body {
    min-height: 100%;
}

body {
    padding: 0!important;
    margin: 0;
    overflow-y: scroll!important;
    font-family:$baseFont;
    font-size: 16px;
    line-height: 1.4;
    color: $colorDark;
    background: $colorLight!important;
}

div, input, button {
    box-sizing: border-box;
}

h1 {
    font-size: 30px;
    font-weight: bold;
    margin: 20px 0;
}
