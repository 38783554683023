@import '../../scss/config';

.VideoCard {
  flex-basis: 100%;
  @media (min-width: $breakpointTablet) {
    flex-basis: calc(50% - 10px);
  }
  @media (min-width: $breakpointDesktop) {
    flex-basis: calc(25% - 15px);
  }
  .MuiSnackbarContent-root {
    min-width: unset!important;
  }
}
