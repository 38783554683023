.FilterItemSlider {
    &__inputs {
        margin-top: 15px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        line-height: 30px;
        input {
            width: 60px;
            height: 30px;
            line-height: 30px;
            padding: 0 0 0 5px;
            &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {  
                opacity: 1;
             }
        }
    }
    &__start {
        margin-right: 10px;
    }
    &__end {
        margin-left: 10px;
    }
    &__setValues {
        margin-left: 10px;
        position: relative;
        top: -1px;
        span.reset {
            cursor: pointer;
            margin-left: 10px;
        }
    }
}