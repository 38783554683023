.Controlbar {
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.53) 61%, rgba(0, 0, 0, 0.65) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.53) 61%, rgba(0, 0, 0, 0.65) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.53) 61%, rgba(0, 0, 0, 0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0); /* IE6-9 */
  color: #fff;
  opacity: 1;
  transition: opacity 250ms ease-in-out;
  &__top {
    display: flex;
    justify-content: space-between;
  }
  &__left {
    display: flex;
    align-items: center;
  }
  &__right {
    display: flex;
    align-items: center;
  }
  &__progress {
    height: 10px;
    min-width: 100%;
  }
  &--hidden {
    opacity: 0;
  }
}

body.hasVideoOverlay {
  .Controlbar--hidden {
    opacity: 1;
  }
}
