.GalleryImages {
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill,minmax(220px,1fr));
  &__image {
    border: 1px solid #ccc;
    border-radius: 4px;
    a {
      aspect-ratio: 4 / 3;
      cursor: pointer;
      max-height: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px;
      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }
}

.pswp__preloader {
  flex-basis: 20px;
  flex-shrink: 1;
}
.pswp_slideshow_speed {
  min-width: 50px;
  display: flex;
  align-items: center;
  div {
    text-align: center;
    height: 25px;
    min-width: 45px;
    border:1px solid #fff;
    color:#fff;
    background: transparent;
    .speed {
      &:after {
        content: 'sek';
        display: inline;
      }
    }
  }
  .slower, .faster {
    padding: 0 10px;
    cursor: pointer;
  }
}

.pswpHideControls {
  .pswp__hide-on-close {
    visibility: hidden;
    &:hover {
      visibility: visible;
    }
  }
}

.pswp__custom-caption {
  background: rgba(255, 255, 255, 0.4);
  font-size: 16px;
  color: #333;
  width: calc(100% - 32px);
  padding: 2px 8px;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 16px;
  transform: translateX(-50%);
}

.hidden-caption-content {
  display: none;
}

.pswp__counter {
  white-space: nowrap;
}
