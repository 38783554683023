@import '../../scss/config';

.Header {
    display: flex;
    justify-content: space-between;
    &__main {
        display: flex;
        justify-content: flex-start;
        align-content: center;
        align-items: center;
    }
    &__logo {
        color: $colorDark;
        text-decoration: none;
        .Logo {
            font-size: 16px;
            position: relative;
            top: 0;
            color: $colorDark;
            text-decoration: none;
            @media (min-width: $breakpointDesktop) {
                font-size: 26px;
                span {
                    margin-right: 5px;
                }
            }
        }
    }
    &__search {
        width: 100%;
        margin-left: 0;
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        padding: 5px;
        form {
            position: relative;
        }
        &__close {
            position: absolute;
            left: 13px;
            top: 10px;
            z-index: 200;
            font-size: 24px;
            color: #969696;
            cursor: pointer;
            &:hover {
                color: $colorDark;
            }
            @media (min-width: $breakpointDesktop) {
                display: none;
            }
        }
        input {
            width: 100%;
            height: 35px;
            padding: 0 40px 0 10px;
            border: 1px solid $colorGreyMiddle;
        }
        button {
            background: transparent;
            width: 18px;
            height: 27px;
            padding: 0;
            position: absolute;
            right: 10px;
            top: 6px;
            display: inline-block;
            border: none;
            cursor: pointer;
            outline: none;
        }
        @media (min-width: $breakpointDesktop) {
            width: 540px;
            margin-left: 20px;
            display: inline-block;
            position: relative;
            input {
                height: 40px;
            }
            button {
                background-size: 20px 35px;
                width: 20px;
                height: 35px;
            }
        }
    }
    &__user {
        display: flex;
        align-items: center;
        position: relative;
        padding-right: 15px;
        .MuiAvatar-root {
            height: 30px;
            width: 30px;
        }
        @media (min-width: $breakpointDesktop) {
            height: auto;
            .MuiAvatar-root {
                height: 40px;
                width: 40px;
            }
        }
        &__name {
            line-height: 1;
            margin-left: 10px;
            @media (min-width: $breakpointDesktop) {
                line-height: 1.4;
            }
        }
        &__searchIcon {
            display: flex;
            margin-right: 10px;
            cursor: pointer;
            @media (min-width: $breakpointDesktop) {
                display: none;
            }
        }
        &__logout {
            cursor: pointer;
            color: $colorGreyMiddle;
            font-size: 15px;
            line-height: 15px;
        }
    }
}
