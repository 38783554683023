.UpdatedPlayer {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: relative;
  margin-bottom: 30px;
  outline: none;
  video {
    display: block;
    outline: none;
    appearance: none;
  }
  &__controls {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
