.Filter {
    margin-top: 10px;
    padding: 0 20px;
}
.FilterItem  {
    margin-bottom: 30px;
    &__name {
        text-transform: uppercase;
        margin-bottom: 10px;
        .remove {
            text-transform: none;
            font-size: 12px;
            color: #8b8b8b;
            margin-left: 10px;
            position: relative;
            top: -1px;
            &:before {
                content: '×';
                padding-right: 3px;
                display: inline-block;
            }
            &:hover {
                color: #707070;
            }
        }
    }
}