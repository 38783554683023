.Videos {
  .MuiSelect-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.selectWithIcon {
  display: flex!important;
  justify-content: space-between!important;
  align-items: center!important;
}
