@import '../../../../scss/config';

.Explainer {
  margin-bottom: 10px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    &__number {
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $colorPrimary;
      color: $colorLight;
      font-weight: bold;
      font-size: 20px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      margin-right: 10px;
    }
  }
  &__content {
    min-height: 5px;
    padding-bottom: 25px;
    padding-top: 15px;
    padding-left: 20px;
    margin-left: 15px;
    border-left: 1px solid $colorGreyLighter;
    > div {
      &:last-child {
        margin-bottom: 0;
      }
    }
    &--empty {
      padding: 10px 0;
    }
  }
  &--disabled {
    color: $colorGreyMiddle;
    .Explainer__header__number {
      background: $colorGreyLighter;
    }
  }
  &--last {
    .Explainer__content--empty {
      display: none;
    }
  }
}
