@import '../../../scss/config';

.MarkerList {
  &__marker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:hover {
      background: $colorGreyLight;
    }
    &__label {
      flex-grow: 1;
      cursor: pointer;
      span {
        margin-left: 3px;
        font-size: 12px;
        color: $colorGreyMiddle;
      }
    }
  }
  .MarkerList__marker--editing {
    background: transparent!important;
  }
  .TextInputWithConfirmCancel {
    margin: 5px 0;
  }
}
