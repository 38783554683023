@import './_config.scss';

#detectionPixel {
  width: 0;
  height: 0;
  line-height: 0;
  display: none;
  background-color: transparent;
  @media screen and (min-width: $breakpointTablet) {
    width: 1px;
  }
  @media screen and (min-width: $breakpointDesktop) {
    width: 2px;
  }
}
