.MoreVideosList {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    width: 100%;
}

.MoreVideosTeaserItem {
    width: 100%;
    display: flex;
    color: #333;
    margin-bottom: 8px;
    text-decoration: none;
    &__title {
        font-size: 15px;
        margin-bottom: 3px;
        position: relative;
    }
    &__year {
        font-size: 14px;
        font-weight: 300;
    }
    &__image {
        width: 30%;
        padding-right: 15px;
        > div {
            position: relative;
            img {
                width: 100%;
                vertical-align: middle;
            }
        }
    }
    &__body {
        width: 70%;
    }
    &__duration {
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #fff;
        background: #333;
        padding: 2px 5px;
        opacity: 0.7;
        font-size: 14px;
    }
}