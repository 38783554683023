@import '../../scss/config';

.Counter {
    display: flex;
    margin-bottom: 50px;
    &__item {
        text-align: center;
        width: 50%;
        &__count {
            font-size: 35px;
            font-weight: normal;
            line-height: 1.1;
        }
        &__label {
            font-size: 18px;
        }
    }
}

@media (min-width: $breakpointTablet) {
    .Counter {
        &__item {
            &__count {
                font-size: 78px;
            }
            &__label {
                font-size: 28px;
            }
        }
    }
}

@media (min-width: $breakpointDesktop) {
    .Counter {
        &__item {
            &__count {
                font-size: 120px;
            }
        }
    }
}
