@import '../../../scss/config';

.VideoTeaserItem {
    
    width: 100%;
    margin-right: 0;
    margin-bottom: 30px;
    position: relative;
    padding: 5px;
    border-radius: 5px;
    
    @media (min-width: 630px) {
        width: 31.3333%;
        margin-right: 2%;
    }
    @media (min-width: 1200px) {
        width: 19%;
        margin-right: 1%;
    }
    @media (min-width: 1700px) {
        width: 15.66666%;
        margin-right: 1%;
    }

    button {
        display: none;
        position: absolute;
        top: 2px;
        right: 2px;
        z-index: 3;
    }
    
    a  {
        color: #333;
        text-decoration: none;
    }
    &__title {
        font-size: 15px;
        margin-bottom: 3px;
        max-width: 100%;
        line-height: 20px;
        max-height: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__year {
        font-size: 14px;
        font-weight: 300;
    }
    &__image {
        position: relative;
        width: 100%;
        padding-top: 80%; /*aspect ratio*/
        margin-bottom: 10px;
        overflow: hidden;
        border-radius: 5px;
        img {
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            text-align: center;
            height: 100%;
            margin: auto;
        }
    }
   &__duration {
        position: absolute;
        bottom: 5px;
        right: 5px;
        color: #fff;
        background: #333;
        padding: 2px 5px;
        opacity: 0.7;
        font-size: 14px;
    }
    &:hover {
        box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
        button {
            display: block;
        }
    }
}