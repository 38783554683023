@import '../../scss/config';
.Grid {
  > div {
    margin-bottom: 20px;
  }
}
@media (min-width: $breakpointTablet) {
  .Grid {
    display: grid;
    grid-gap: 10px;
    padding: 10px;
    > div {
      margin-bottom: 0;
    }
  }
}
