@import '../../../scss/config';

.Message {
    padding: .75rem 1.25rem;
    margin-bottom: 20px;
    &--error {
        border: 1px solid $colorErrorLight;
        color: $colorErrorDark;
        background-color: $colorErrorLight;
    }
    &--success {
        border: 1px solid $colorSuccessLight;
        color: $colorSuccessDark;
        background-color: $colorSuccessLight;
    }
}