.GalleriesList {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  width: 100%;
}

.Gallery {
  width: 100%;
  margin-right: 0;
  margin-bottom: 30px;
  position: relative;
  padding: 5px;
  border-radius: 5px;

  @media (min-width: 630px) {
    width: 31.3333%;
    margin-right: 2%;
  }
  @media (min-width: 1200px) {
    width: 19%;
    margin-right: 1%;
  }
  @media (min-width: 1700px) {
    width: 15.66666%;
    margin-right: 1%;
  }
  a  {
    color: #333;
    text-decoration: none;
  }
  &__title {
    font-size: 15px;
    margin-bottom: 3px;
    max-width: 100%;
    line-height: 20px;
    max-height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  &__count {
    font-size: 14px;
    font-weight: 300;
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    height: 200px;
    overflow: hidden;
    img {
      min-width: 100%;
      min-height: 100%;
    }
  }
  &:hover {
    box-shadow: 0 0 15px rgba(0,0,0,0.1);
    button {
      display: block;
    }
  }
}
