@import '../../../../scss/config';

.TextInputWithConfirmCancel {
  display: flex;
  align-items: center;
  min-width: 100%;
  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54);
  }
  &__input {
    flex-basis: 100%;
    flex-grow: 1;
  }
  &__icons {
    white-space: nowrap;
  }
  input[type="text"] {
    border: none;
    border-bottom: 1px solid $colorGreyDark;
    height: 30px;
    line-height: 30px;
    width: 100%;
    outline: none;
    appearance: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    letter-spacing: inherit;
  }
}
