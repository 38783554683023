$baseFont: 'Roboto', Arial, sans-serif;

$colorDark: #333;
$colorLight: #fff;
$colorGreyDark: #676767;
$colorGreyMiddle: #8b8b8b;
$colorGreyLighter: #eaeaea;
$colorGreyLight: #F3F3F3;
$colorPrimary: #177c84;
$colorErrorLight: #f5c6cb;
$colorErrorDark: #721c24;
$colorSuccessLight: #73e08b;
$colorSuccessDark: #008337;
$colorHighlight: #E62243;

$breakpointDesktop: 1000px;
$breakpointTablet: 768px;
$breakpointMobileMax: 999px;
