.Scrubber {
  width: 100%;
  height: 30px;
  position: relative;
  top: -2px;
  user-select: none;
  touch-action: none;
  * {
    user-select: none;
  }
  &__bar {
    background: rgba(65, 65, 65, 0.6);
    position: relative;
    transition: height 0.2s linear, width 0.2s linear;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 6px;
    width: 100%;
    &__progress {
      position: absolute;
      background: red;
      height: 100%;
    }
    &__buffer {
      position: absolute;
      background: rgba(170, 170, 170, 0.6);
      height: 100%;
    }
    &__thumb {
      position: absolute;
      width: 0;
      height: 0;
      border-radius: 10px;
      background: red;
      transition: height 0.2s linear, width 0.2s linear;
      transform: translate(-50%, -50%);
      top: 50%;
      cursor: grab;
    }
    &__marker {
      display: none;
      height: 100%;
      position: absolute;
      background: yellow;
      width: 12px;
    }
  }
  &--hover {
    .Scrubber__bar {
      height: 8px;
    }
    .Scrubber__bar__thumb {
      width: 20px;
      height: 20px;
      box-shadow: 0px 0px 6px -1px rgba(0,0,0,0.75);
    }
    .Scrubber__bar__marker {
      display: block;
    }
  }
}
