.VideoDetailPage {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 30px;
    .Video {
        width: 100%;
        @media (min-width: 1000px) {
            width: 70%;
        }
    }
    .VideoSideBar {
        width: 100%;
        margin-top: 30px;
        @media (min-width: 1000px) {
            width: 30%;
            padding-left: 30px;
            margin-top: 0;
        }
        .VideoMarkers {
            margin-bottom: 20px;
        }
    }
}
