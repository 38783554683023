.NavigationBar {
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        &.main {
            margin-bottom: 60px;
            li {
                &:last-child a:after {
                    content:'';
                    display: inline-block;
                    background: #D9D9D9;
                    width: 100%;
                    position: absolute;
                    bottom: -40px;
                    left: 0;
                    height: 1px;
                }
            }
        }
        li {
            a {
                padding: 10px 20px;
                color: #333;
                text-decoration: none;
                display: block;
                position: relative;
                &.active {
                    background: #E1E1E1;
                }
            }
        }
    }
}