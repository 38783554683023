@import '../../../scss/config';

.VideoMarkers {
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
            cursor: pointer;
            position: relative;
            min-height: 24px;
            padding-top: 2px;
            margin-bottom: 4px;
            span {
                margin-left: 5px;
                font-size: 12px;
                color: $colorGreyMiddle;
            }
            &:hover {
                background: $colorGreyLight;
            }
        }
    }
}
