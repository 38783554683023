.EditVideo {
  .VideoForm {
    &__field {
      margin-bottom: 30px;
    }
  }
  &__player {
    display: flex;
    &__markers {
      flex-basis: 300px;
      flex-grow: 0;
      flex-shrink: 0;
      img {
        max-width: 100%;
      }
    }
    &__video {
      flex-grow: 1;
      padding-right: 15px;
    }
  }
}
